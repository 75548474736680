import { Workbox } from 'workbox-window';
export { FloatingPanel } from './webComponents/floatingPanel';
import { FloatingPanel } from './webComponents/floatingPanel';
import 'signalr';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export { Tab, TabPane } from './webComponents/tabControls'
export { UserNoteEditor } from './webComponents/UserNoteEditor';
export { UserNote } from './webComponents/userNotes/UserNote';
export { ContentSwitcher } from './webComponents/ContentSwitcher';
export { DraggableItem } from './webComponents/dragDrop/draggableItem';
export { DropPoint } from './webComponents/dragDrop/dropPoint';
export { ModalDialog } from './webComponents/modalDialog';
export { PerfectSquare } from './webComponents/perfectSquare';
export { NotificationList } from './webComponents/notifications/notificationList';
export { ToggleSwitchInput } from './webComponents/toggleSwitch/toggle-switch-input';
export { ToggleSwitch } from './webComponents/toggleSwitch/toggleSwitch';
export { ProposalTile } from './webComponents/proposalTile';
export { CharacterLimit } from './webComponents/CharacterLimit';
export { HtmlEditor } from './webComponents/HtmlEditor';

import { UserNote } from './webComponents/userNotes/UserNote';
import { UserNoteEditor } from './webComponents/UserNoteEditor';
import { ContentSwitcher } from './webComponents/ContentSwitcher';
import { DropPoint } from './webComponents/dragDrop/dropPoint';
import { ModalDialog } from './webComponents/modalDialog';


let notesList = document.getElementById("user-notes-container");

const addListeners = () => {

	//event handler for when the delete button is clicked in a user note
	notesList.addEventListener("deleteRequested", (ev: CustomEvent) => {
		let note = ev.target as UserNote;

		//get the template for the modal we'll show
		let template = document.getElementById("delete-note-template") as HTMLTemplateElement;

		let modalDocumentFragment = template.content.cloneNode(true) as DocumentFragment; //document.createElement('modal-dialog') as ModalDialog;

		let modal = modalDocumentFragment.children[0] as ModalDialog;

		//modal.body = 'Are you sure you want to Delete this Note?';
		modal.saveText = 'Delete';
		modal.cancelText = 'Cancel';
		//modal.heading = "Delete Note";
		modal.addEventListener('primarybuttonclicked', (e: CustomEvent) => {
			(async () => {
				let fetchResult = await fetch(`/usernote/delete?id=${ev.detail as string}`);
				let succeeded: boolean = await fetchResult.json();

				let parent: HTMLElement = note.parentElement;
				let parentFound = false;
				do {
					if (parent instanceof DropPoint) {
						parent.remove();
						parentFound = true;
						continue;
					}
					parent = parent.parentElement;
				} while (!parentFound);
			})();
		});

		modal.addEventListener("secondarybuttonclicked", (ev: CustomEvent) => {
			note.isDeleting = false;
		});

		document.getElementById('modal-view-container').appendChild(modal);
	});

	//event handler for when the edit button is clicked
	notesList.addEventListener('editRequested', (ev: CustomEvent) => {
		(async () => {
			let target = ev.target as UserNote;
			let contentSwitch = target.parentElement as ContentSwitcher;
			contentSwitch.switch();
		})();
	});

	notesList.addEventListener('editCancelled', (ev: CustomEvent) => {
		//User decided to cancel an edit to a note, so we now want it's parent content switcher to switch
		let noteElement = ev.detail as UserNoteEditor;

		let nodeNotFound = true;
		let currentElement: HTMLElement = noteElement;
		while (nodeNotFound) {
			//Is the currentElement a content-switcher
			if (currentElement instanceof ContentSwitcher) {
				nodeNotFound = false;
				let contentSwitcher: ContentSwitcher = currentElement as ContentSwitcher;
				contentSwitcher.switch();

				continue;
			}

			currentElement = currentElement.parentElement;
		}
	});
}

if (notesList) {
    addListeners();
}