import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LitElement, html } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import { customElement } from '../customElement';

@customElement('html-editor')
export class HtmlEditor extends LitElement {
    createRenderRoot() {
        return this;
    }

    @property()
    text: string;

    @property()
    name: string;

    @query('textarea')
    noteTextArea: HTMLTextAreaElement;

    @property()
    height?: number = undefined;

    private editor: ClassicEditor;

    async disable() {
        await this.editor.destroy();
        this.editor = null;
    }

    firstUpdated(_changedProperties: Map<string | number | symbol, unknown>) {
        super.firstUpdated(_changedProperties);
        const cthis = this;
        (async () => {
            const ed = await ClassicEditor.create(this.noteTextArea, {
                toolbar: ['heading', 'paragraphs', 'bold', 'italic', 'undo', 'redo', 'numberedList', 'bulletedList', 'blockQuote']
            });
            
            this.editor = ed;
        })();
    }

    connectedCallback() {
        super.connectedCallback();
        this.onkeyup = (e) => {
            const textArea = this.noteTextArea;
            const text = this.editor.getData();
            
            textArea.value = text;
            this.text = text;
        };
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.editor.destroy();
        this.editor = null;
    }

    render() {
        return html`<textarea rows="${this.height ?? 5}" name="${this.name}">${this.text}</textarea>`;
    }
}